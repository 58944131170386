import { Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { DataGridPro, DataGridProProps, GridToolbar } from "@mui/x-data-grid-pro";
import { getDataGridColumns, saveDataGridColumns } from "src/utils/userSettingsUtil";

type DataGridProps = DataGridProProps & {
  isLoading: boolean;
  dataGridId?: string;
  containerHeight?: number | string;
  containerWidth?: number | string;
  defaultPageSize?: number;
  marginTop?: number;
};

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  color: '#707070',
  '& .no-rows-primary': {
    fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#3D4751',
  },
  '& .no-rows-secondary': {
    fill: theme.palette.mode === 'light' ? '#E8EAED' : '#1D2126',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 4 }}>No results found. Please try a different search term.</Box>
    </StyledGridOverlay>
  );
}

export const DataGrid: React.FC<DataGridProps> = ({
  isLoading,
  dataGridId,
  containerHeight = 800,
  containerWidth = "100%",
  defaultPageSize = 100,
  marginTop,
  columns,
  rows,
  autoHeight,
  ...rest
}: DataGridProps) => {
  const theme = useTheme();

  if (dataGridId) {
    columns = getDataGridColumns(dataGridId, columns);
  }

  const onColumnChange = (e) => {
    saveDataGridColumns(dataGridId, columns, e);
  };

  if (autoHeight) {
    containerHeight = rows?.length === 0 ? 300 : "auto";
  }

  return (
    <Box component="section" sx={{ height: containerHeight, width: containerWidth }} marginTop={marginTop}>
      <DataGridPro
        rows={rows}
        columns={columns}
        sx={{
          fontSize: 14,
          fontWeight: 400,
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: theme.palette.grey[200],
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 800,
            fontSize: 16,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: defaultPageSize,
            },
          },
        }}
        slots={{
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            sx: {
              alignSelf: "flex-end",
            }   
          },
        }}
        loading={isLoading}
        hideFooterRowCount={true}
        onColumnOrderChange={(e) => onColumnChange(e)}
        onColumnResize={(e) => onColumnChange(e)}
        onColumnVisibilityModelChange={(e) => onColumnChange(e)}
        {...rest}
      />
    </Box>
  );
};

export default DataGrid;
