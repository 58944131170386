import { StrictMode } from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { StateMachineProvider, createStore } from "little-state-machine";
import LoadingProvider from "./components/Context/LoadingProvider";
import { ThemeName, ThemeProvider } from "@jbhi-fi/lanyard-ui";
import GlobalStyles from "@jbhi-fi/lanyard-ui/themes/GlobalStyles";
import JbGlobalTheme from "@jbhi-fi/lanyard-ui/themes/jb/JbGlobalTheme";
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { theme } from "./theme";
import PageProvider from "./components/Context/PageProvider";
import CountryProvider from "./components/Context/CountryProvider";
import App from "./App";
import state from "./store/state";

createStore(state);

ReactDOM.render(
  <StrictMode>
    <StateMachineProvider>
      <StyledEngineProvider injectFirst>
        <CountryProvider>
          <ThemeProvider theme={ThemeName.jbhifi}>
            <JbGlobalTheme />
            <GlobalStyles />
            <MuiThemeProvider theme={theme}>
              <PageProvider>
                <LoadingProvider>
                  <Router>
                    <App />
                  </Router>
                </LoadingProvider>
              </PageProvider>
            </MuiThemeProvider>
          </ThemeProvider>
        </CountryProvider>
      </StyledEngineProvider>
    </StateMachineProvider>
  </StrictMode>,
  document.getElementById("root")
);
